/* In your index.css or App.scss */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: 100%;
  width: 100%;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Ubuntu", "Maven Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1800px;
  margin: auto;
  
  color: #ffffff;
  font-weight: 400;
   scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* website color  */
:root {
  --headingFont: " Ubuntu";
  --paragraphFont: "Maven Pro";
  --text-normal: #ffffff;
  --text-primary: #f6f6f6;
  --background: #131313b2; /*70% opacity */
  --primary-border: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(108.65deg, #ffffff -26.25%, rgba(255, 255, 255, 0) 31.16%);

  --color-primary-gradient: linear-gradient(
    266.24deg,
    rgba(246, 246, 246, 0.4) -2.72%,
    rgba(246, 246, 246, 0) 10.33%
  );

  --gradient-text: radial-gradient(
    104.21% 376.56% at 50% 50%,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* main home head text color  */

  --bg-primary-light: #f6f6f6cc; /*80% */
  --bg-secondary: #0085d9;
  --bg-sec-gradient: linear-gradient(0deg, #4285f4, #4285f4),
    radial-gradient(
        43.34% 122.55% at 0% -40.2%,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
